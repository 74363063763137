import React from "react"

function HireMe() {
   return (
      <div>
         <h2>Hire Me</h2>
      </div>
   )
}

export default HireMe
