import React, { Component } from "react"

export default class Contact extends Component {
   render() {
      return (
         <div className="container">
            <h1 className="mt-5 text-center fw-lighter">
               Hello From Contact Section
            </h1>
         </div>
      )
   }
}
